import styled, { css } from "styled-components";
import iphoneComposizione from "./assets/images/IPHONE_COMPOSIZIONE.png";
import iphoneMobile from "./assets/images/IPHONE_MOBILE.png";
import Checkmark from "./assets/images/checkmark.png";
import { desktop, fullDesktop, tablet } from "./libs/media";

export const H2TitleSmall = styled.h2`
  color: #ffcc00;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  margin-top: 25px;
`;

export const H1MainTitle = styled.h2`
  color: black;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  margin: 12px 0 22px;
  ${tablet(`
    font-size: 30px;
    line-height: 35px;
  `)}
  ${desktop(`
    font-size: 40px;
    line-height: 50px;
  `)}
`;

export const H3Title = styled.h3`
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
`;

export const MainStrapline = styled.span``;
export const Titles = styled.h1`
  color: black;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  margin: 20px 0 22px;
  ${desktop(`
    font-size: 40px;
    line-height: 50px;
  `)}
`;

export const LeftCol = styled.div<{ tabletCol: number; desktopCol: number }>`
  margin: 0;
  padding: 0;
  box-sizing: content-box;
  ${({ tabletCol }) => tablet(`width: ${tabletCol}%`)}
  ${({ desktopCol }) => desktop(`width: ${desktopCol}%`)}
`;

export const RightCol = styled.div<{ tabletCol: number; desktopCol: number }>`
  margin: 0;
  box-sizing: content-box;

  padding: 0;
  ${({ tabletCol }) => tablet(`width: ${tabletCol}%`)}
  ${({ desktopCol }) => desktop(`width: ${desktopCol}%`)}
`;

export const FlexTwoColsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-top: 25px;
`;

export const Wrapper = styled.div<{
  withMargin?: boolean;
  gapDesktop?: number;
  justifyContentDesktop?: string;
  alignDesktop?: string;
  desktopMinHeight?: number;
}>`
  padding: 16px 30px 36px;
  box-sizing: border-box;
  text-align: left;
  ${({ withMargin }) => !withMargin && `padding: 16px 0px 36px`};
  display: flex;
  flex-direction: column;

  ${({ withMargin }) =>
    tablet(`
    padding: 50px 30px 60px;
    flex-direction: row;
    position: relative;
    gap: 50px;
    justify-content: space-between;
    ${!withMargin && `padding: 50px 0px 60px`};  
  `)}
  ${({
    withMargin,
    justifyContentDesktop,
    gapDesktop,
    alignDesktop,
    desktopMinHeight,
  }) =>
    desktop(`
    padding: 50px 100px 60px;
    flex-direction: row;
    position: relative;
    min-height: ${desktopMinHeight ? `${desktopMinHeight}px` : "400px"};
    gap: ${gapDesktop ? `${gapDesktop}px` : "50px"};
    justify-content: ${
      justifyContentDesktop ? `${justifyContentDesktop}` : "space-between"
    };
    align-items: ${alignDesktop ? `${alignDesktop}` : "flex-start"};
    ${!withMargin && `padding: 50px 0px 60px`};  
  `)}
  
    ${fullDesktop(`
      padding: 50px 30px 60px;
  `)}
`;

export const Box = styled.div`
  max-width: 1512px;
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

export const WrapperCentered = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  & * {
    text-align: center;
  }
  ${tablet(`
    gap: 0px;
    width: 70%;
  `)}
  ${desktop(`
    gap: 0px;
  `)}

  ${H3Title} {
    ${tablet(`
      padding: 0;
      margin: 0 auto;
      width: 80%;
    `)}
    ${desktop(`
      padding: 0;
      margin: 0 auto;
      width: 80%;
    `)}
  }
`;

export const OuterBox = styled.div<{ bgColor?: string }>`
  width: 100%;
  margin: 0 auto;
  background: ${({ bgColor }) => (bgColor ? `${bgColor}` : "inherit")};
  & > * {
    max-width: 1512px;
    margin: 0 auto;
    ${fullDesktop(
      `
      padding-right: 30px;
      padding-left: 30px;
      `
    )}
  }
`;

export const ThirdWrapper = styled(Wrapper)`
  background: black;
  color: white;
  max-height: 560px;
  overflow: hidden;
  & * {
    color: white;
  }
  & > img {
    margin-top: 30px;
  }
  ${tablet(`
    height: 250px;
    overflow:hidden;
    gap: 120px;
    align-items: center;
  `)}
  ${desktop(`
    height: 550px;
    overflow:hidden;
    gap: 120px;
    align-items: center;
  `)}
`;
export const Footer = styled(Wrapper)`
  background: black;
  padding: 30px;
  color: white;
  min-height: unset;
  ${H3Title} {
    ${tablet(`f
      padding: 0;
      margin: 0 auto;
      margin-bottom: 15px;
    `)}
    ${desktop(`
      padding: 0;
      margin: 0 auto;
      margin-bottom: 15px;
    `)}
  }

  ${desktop(`
      padding: 30px 100px;      
    `)}
`;
export const AppLogo = styled.img`
  width: 168px;
`;

export const FooterLeftCol = styled(LeftCol)`
  flex-direction: column;
  justify-content: space-between;
  a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
  }
`;

export const FooterRightCol = styled(RightCol)`
  max-width: 580px;
  justify-content: flex-end;
`;

export const SocialContainer = styled.div`
  display: flex;
  gap: 20px;
  p {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 20px 0;
  }
  img {
    height: 20px;
    margin-top: 3px;
  }
`;

export const FormContainer = styled.form`
  position: relative;
  box-sizing: content-box;
  width: 100%;

  button {
    position: absolute;
    right: 4px;
    width: 80px;
    top: 3px;
    border-radius: 8px;
    margin: 0;
    padding: 7px;
    border: none;
  }

  input {
    border: 2px solid #ffcc00;
    padding: 8px;
    background: transparent;
    color: white;
    width: 100%;
    border-radius: 10px;
    box-sizing: border-box;
  }
`;

export const NavBar = styled.nav`
  background-color: #fff;
  box-shadow: 0 6px 4px 0 #00000015;
  padding: 30px;
  font-size: calc(10px + 2vmin);
  color: black;
  z-index: 99;
  display: block;

  ${desktop(`
      padding: 30px 100px;
  `)}
`;

export const List = styled.ul`
  list-style-image: url(${Checkmark});
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 23px;
  margin-left: -25px;
  ${desktop(`
    margin-left: 25px;
  `)}
  li {
    padding-left: 5px;
    font-weight: 500;
    line-height: 18px;
    ${desktop(`
    line-height: 25px;
  `)}
  }
`;

export const Titles2 = styled(Titles)`
  font-size: 20px;
  line-height: 25px;

  ${desktop(`
     font-size: 35px;
      line-height: 45px;
  `)}
`;

export const CustomImage = styled.img<{ size?: number }>`
  width: ${({ size }) => (size ? `${size}px` : "100%")};
`;

export const CustomImageDetails = styled(CustomImage)`
  width: 100%;
  margin-top: 40px;
  ${tablet(`
    width: 200px;
    transform: translate(0, 90px)
  `)}
  ${desktop(`
    width: 450px;    
    transform: translate(-60px, 250px)
  `)}
`;

export const HeroImageBackgroundSplash = styled.div`
  background: url(${iphoneMobile}) no-repeat top center;
  background-size: cover;
  margin: -12px 0 -36px;
  ${tablet(`
    background: url(${iphoneComposizione}) no-repeat top left;
    position: absolute;
    background-size: cover;
    top: -90px;
    right: 0;
    height: 600px;
    width: 400px;
    z-index:-1;
    isolation: isolate;
  `)}
  ${desktop(`
    background: url(${iphoneComposizione}) no-repeat top center;
    position: absolute;
    background-size: cover;
    top: -90px;
    right: 20px;
    height: 700px;
    width: 700px;
    z-index:-1;
    isolation: isolate;
  `)}
`;

export const Margins = styled.div`
  padding-left: 30px;
  padding-right: 30px;

  ${desktop(`
    padding-left: 100px ;
    padding-right: 100px;
  `)}
  ${fullDesktop(`
    padding-left: 30px;
    padding-right: 30px;
  `)}
`;

export const StackedPhonesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 50px;
`;

export const CustomImageLeftPhone = styled(CustomImage)`
  width: 50%;
`;

export const CustomImageRightPhone = styled(CustomImage)`
  width: 50%;
  transform: translate(0, -50px);
`;

export const CustomImageOperator = styled(CustomImage)`
  margin: 20px 0 -40px;
`;

export const CardWrapper = styled(Wrapper)`
  ${tablet(`
    flex-direction: column;
  `)}
  ${desktop(`
    flex-direction: column;
  `)}
`;
export const Card = styled.div<{ withBorder?: boolean }>`
  border: 2px solid #ffcc00;
  border-radius: 16px;
  padding: 0px 30px 25px;
  margin-top: 20px;
  background: #ffcc00;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ${({ withBorder }) =>
    withBorder &&
    css({
      "margin-bottom": "-40px",
      background: "white",
    })}

  ${Titles} {
    line-height: 25px;
    margin-bottom: 11px;
    ${tablet(`
      padding: 0;
      margin: 0;
    `)}
    ${desktop(`
      padding: 0;
      margin: 0;
      line-height: 45px;
      font-size: 35px;
    `)}
  }

  ${tablet(`
    border-radius: 8px;
    gap: 50px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 40px 30px;
  `)}
  ${desktop(`
    border-radius: 8px;
    gap: 100px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 40px 30px;
    padding-bottom: 50px;
  `)}

  ${({ withBorder }) => withBorder && tablet(`margin-bottom: -100px;`)}
  ${({ withBorder }) =>
    withBorder && desktop(`margin-bottom: -100px;padding-bottom: 40px;`)}

  ${LeftCol} {
    ${desktop(`width: 38%`)}
  }
  ${RightCol} {
    ${desktop(`width: 45%`)}
  }
`;

export const PrivacyPolicy = styled.a`
  color: white;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
`;

export const BigTextContainer = styled.div``;
export const BigTextTitle = styled.div`
  font-size: 35px;
  font-weight: bold;
  display: block;
  margin-top: 22px;
`;
export const BigTextSubtitle = styled.div`
  display: block;
`;

export const Button = styled.button`
  background: #ffcc00;
  color: black;
  border-radius: 12px;
  padding: 16px 32px;
  font-weight: bold;
  margin: 30px 0;
`;
