enum Orientation {
  Portrait = "portrait",
  Landscape = "landscape",
}

export enum Device {
  desktop = "desktop",
  tablet = "tablet",
  fullDesktop = "fullDesktop",
}

export const DeviceMap: { [key in keyof typeof Device]: string } = {
  [Device.desktop]: "screen and (min-device-width: 1024px)",
  [Device.tablet]:
    "screen and (min-device-width: 680px) and (max-device-width: 1024px)",
  [Device.fullDesktop]: "screen and (min-device-width: 1512px)",
};

export function landscape(device: Device, css: string): string;
export function landscape(css: string): string;
export function landscape(cssOrDevice: Device | string, css?: string): string {
  if (Object.values(Device).indexOf(cssOrDevice as Device) > -1) {
    return `
      body.landscape-navigation & {
        @media ${DeviceMap[cssOrDevice as Device]} {${css}}
      }
    `;
  } else {
    return `body.landscape-navigation & {${cssOrDevice}}`;
  }
}

export function tabletPortrait(css: string): string;
export function tabletPortrait(device: Device, css: string): string;
export function tabletPortrait(
  cssOrDevice: Device | string,
  css?: string
): string {
  const mediaQuery =
    cssOrDevice in Device
      ? DeviceMap[cssOrDevice as Device]
      : DeviceMap[Device.tablet];

  const cssProperties = css || cssOrDevice;

  return `
  @media ${mediaQuery} and (orientation: portrait) {
    ${cssProperties}
  }
`;
}

export function tablet(device: string, css: string): string;
export function tablet(css: string): string;
export function tablet(cssOrDevice: string, css?: string): string {
  const mediaQuery =
    cssOrDevice in Device
      ? DeviceMap[cssOrDevice as Device]
      : DeviceMap[Device.tablet];

  const cssProperties = css || cssOrDevice;

  return `
    @media ${mediaQuery} {
      ${cssProperties}
    }
  `;
}
export function desktop(device: string, css: string): string;
export function desktop(css: string): string;
export function desktop(cssOrDevice: string, css?: string): string {
  const mediaQuery =
    cssOrDevice in Device
      ? DeviceMap[cssOrDevice as Device]
      : DeviceMap[Device.desktop];

  const cssProperties = css || cssOrDevice;

  return `
    @media ${mediaQuery} {
      ${cssProperties}
    }
  `;
}
export function fullDesktop(device: string, css: string): string;
export function fullDesktop(css: string): string;
export function fullDesktop(cssOrDevice: string, css?: string): string {
  const mediaQuery =
    cssOrDevice in Device
      ? DeviceMap[cssOrDevice as Device]
      : DeviceMap[Device.fullDesktop];

  const cssProperties = css || cssOrDevice;

  return `
    @media ${mediaQuery} {
      ${cssProperties}
    }
  `;
}

/**
 HOW TO USE THESE HELPER FUNCTIONS:

 * ? ipadPro was removed as it is defaulting to the tablet media query

  here's an example:

  const NewComponent = styled.div`
    // This is the default style used for tower
    color: red;

    // This is the default style used for tablets (so, by default, all the ipads)
    ${tablet("color: blue")}

    // If we need to overwrite some properties for the smaller ipad mini we can do it by calling the tablet function with the device as first parameter and this will overwrite the default (tablet) properties applied above (if on an ipad mini)
    ${tablet(Device.ipadMini, "color: green")}

    // This landscape is the function to use for landscape Mode and the style present in here will be applied for every device in landscape
    ${landscapeMode("color: lightblue; background: blue")}

    // And again, if we need to overwrite some properties for the ipadMini (when on landscape mode) we can do it by calling the landscape function with the device as first parameter (like above)
    ${landscapeMode(Device.ipadMini, "color: lightgreen; background: green")}
  `;

**/
