import styled from "styled-components";
import appStore from "../assets/images/app store.png";
import playStore from "../assets/images/play store.png";
import {desktop, tablet} from "../libs/media";

export const StoreContainer = styled.div<{centered?: boolean}>`
  margin: 24px 0;
  display: flex;
  ${({centered}) => (centered ? "align-items: center; justify-content: center;" : "")}
`;

const AppStore = styled.img`
  width: 90px;
  margin-right: 24px;
  cursor: pointer;
  ${tablet(`width: 120px;height: auto;`)}
  ${desktop(`width: 140px;height: auto;`)}
`;

const PlayStore = styled.img`
  width: 102px;
  cursor: pointer;
  ${tablet(`width: 120px;height: auto;`)}
  ${desktop(`width: 160px;height: auto;`)}
`;

export const StoreButtons = ({centered}: {centered?: boolean}) => {
  return (
    <StoreContainer centered={centered}>
      <AppStore
        alt="Scarica l'app dal'app store"
        src={appStore}
        onClick={() =>
          window.open(
            "https://apps.apple.com/it/app/astauto-italia/id6449590440",
            "_blank"
          )
        }
      />
      <PlayStore
        alt="Scarica l'app dal play store"
        src={playStore}
        onClick={() =>
          window.open(
            "https://play.google.com/store/apps/details?id=app.astauto.to&pli=1",
            "_blank"
          )
        }
      />
    </StoreContainer>
  );
};
