import { debounce } from 'lodash'
import { useEffect, useState } from 'react'
import { DefaultTheme, SeparatedTheme, ThemeColors, css } from 'styled-components'
import { Device, DeviceMap } from './media'

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : DeepPartial<T[P]>
}

const PXTOREM_RATIO = 16

export const pxToRem = (pxValue: number, zoomRatio = 1) => {
  //This ratio is the one used as a base font for the body to calculate all the rem sizes to use
  return `${((pxValue / PXTOREM_RATIO) * zoomRatio).toFixed(5)}rem`
}

export const checkColor = (
  color: string,
  theme: DefaultTheme,
  separatedThemeName?: SeparatedTheme
) => {
  const colorsObject = separatedThemeName ? theme[separatedThemeName].colors : theme.colors
  const isColorFromTheme = colorsObject.hasOwnProperty(color) || false
  const textColor = isColorFromTheme ? colorsObject[color as keyof DeepPartial<ThemeColors>] : color
  return textColor
}

type MapDevicePx = { [key in Device]?: number }

const enumKeys = <I extends object, O extends keyof I>(obj: I): O[] => {
  return Object.keys(obj) as O[]
}

export const pxByDevice = (mapDevicePx: MapDevicePx) => {
  for (const device of enumKeys(mapDevicePx)) {
    if (window.matchMedia(DeviceMap[device]).matches) {
      return mapDevicePx[device]
    }
  }
  return mapDevicePx.desktop
}

export const clampBuilder = (
  minWidthPx: number,
  maxWidthPx: number,
  minPxSize: number,
  maxPxSize: number,
  zoomRatio: number
) => {
  const minWidth = minWidthPx / PXTOREM_RATIO
  const maxWidth = maxWidthPx / PXTOREM_RATIO

  const minRemWidth = (minPxSize / PXTOREM_RATIO) * zoomRatio
  const maxRemWidth = (maxPxSize / PXTOREM_RATIO) * zoomRatio

  const slope = (maxRemWidth - minRemWidth) / (maxWidth - minWidth)
  const yAxisIntersection = -minWidth * slope + minRemWidth

  const calculatedPreferredValue = `${yAxisIntersection.toFixed(4)}rem + ${(slope * 100).toFixed(
    4
  )}vw`

  return `clamp(${Math.min(minRemWidth, maxRemWidth)}rem, ${calculatedPreferredValue}, ${Math.max(
    minRemWidth,
    maxRemWidth
  )}rem)`
}

export const clampValues = (min: number, max: number, zoomRatio = 1) => {
  return clampBuilder(390, 1512, min, max, zoomRatio)
}

export const clampValuesLandscape = (min: number, max: number, zoomRatio = 1) => {
  return clampBuilder(1024, 1366, min, max, zoomRatio)
}

export const withLineClamp = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`

export const lineClamp = (rows: number) => css`
  line-clamp: ${rows};
  -webkit-line-clamp: ${rows};
`

export const useMediaQuery = (query: string) => {
  const [queryMatch, setQueryMatch] = useState<boolean | null>(null)

  useEffect(() => {
    const handleQueryListener = debounce(() => {
      const updatedMatches = window.matchMedia(query)
      setQueryMatch(updatedMatches.matches)
    }, 250)

    handleQueryListener()
    window.addEventListener('resize', handleQueryListener)

    return () => {
      window.removeEventListener('resize', handleQueryListener)
    }
  }, [query])

  return queryMatch
}
