import { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import iphone2 from "./assets/images/IPHONE2.png";
import iphone3 from "./assets/images/IPHONE3.png";
import iphone4 from "./assets/images/IPHONE4.png";

import facebook from "./assets/images/facebook.png";
import instagram from "./assets/images/instagram.png";
import logoWhite from "./assets/images/logo-white.png";
import logo from "./assets/images/logo.png";
import operatorTablet from "./assets/images/operator-square.png";
import operator from "./assets/images/operator.png";

import { StoreButtons } from "./components/StoreButtons";
import { DeviceMap } from "./libs/media";
import { useMediaQuery } from "./libs/styled";
import {
  AppLogo,
  BigTextContainer,
  BigTextSubtitle,
  BigTextTitle,
  Box,
  Button,
  Card,
  CardWrapper,
  CustomImageDetails,
  CustomImageLeftPhone,
  CustomImageOperator,
  CustomImageRightPhone,
  FlexTwoColsContainer,
  Footer,
  FooterLeftCol,
  FooterRightCol,
  FormContainer,
  H1MainTitle,
  H2TitleSmall,
  H3Title,
  HeroImageBackgroundSplash,
  LeftCol,
  List,
  MainStrapline,
  Margins,
  NavBar,
  OuterBox,
  PrivacyPolicy,
  RightCol,
  SocialContainer,
  StackedPhonesContainer,
  ThirdWrapper,
  Titles,
  Titles2,
  Wrapper,
  WrapperCentered,
} from "./styles";

type Inputs = {
  email: string;
};

function App() {
  const isTablet = useMediaQuery(DeviceMap["tablet"]);
  const isDesktop = useMediaQuery(DeviceMap["desktop"]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();
  const [addressAdded, setaddressAdded] = useState<string>("false");
  const widget = useRef<HTMLDivElement>(null);
  const sc = document.createElement("script");
  sc.setAttribute("defer", "true");
  sc.setAttribute(
    "src",
    "https://dbwx2z9xa7qt9.cloudfront.net/bundle.js?cachebust=1684596063311"
  );
  sc.setAttribute("theme", "light");
  sc.setAttribute("footer", "true");
  sc.setAttribute("widget-type", "carousel");
  sc.setAttribute("token", "616d586a7b0cce5c8ecee333");
  sc.setAttribute("apiurl", "https://server.onlinereviews.tech/api/v0.0.9");
  sc.setAttribute("stats", "false");
  sc.setAttribute("addReview", "false");
  sc.setAttribute("profile-pic", "false");
  sc.setAttribute("review-name", "true");
  sc.setAttribute("wl", "false");
  sc.setAttribute("wlndig", "https://go.climbo.com/astauto-srl");
  sc.setAttribute("lang", "en");

  useEffect(() => {
    if (!widget) return;
    widget.current?.appendChild(sc);
  }, []);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    setaddressAdded("loading");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: data.email,
    });

    var requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://europe-west3-astauto-45e14.cloudfunctions.net/mailList",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        setaddressAdded("true");
      })
      .catch((error) => {
        console.log("error", error);
        setaddressAdded("error");
      });
  };
  console.log("🚀 ~ file: App.tsx:26 ~ App ~ errors:", errors.email);

  return (
    <div className="App">
      <NavBar>
        <Box>
          <AppLogo src={logo} alt="logo" />
        </Box>
      </NavBar>

      <Wrapper withMargin={false} desktopMinHeight={600}>
        <Box>
          <LeftCol tabletCol={60} desktopCol={55}>
            <Margins>
              <H2TitleSmall>Astauto app</H2TitleSmall>
              <H1MainTitle>
                La prima casa d’asta digitale per privati nel settore auto usate
              </H1MainTitle>
              <MainStrapline>
                Astauto è la prima applicazione per aste digitali che ti
                permette di acquistare e vendere la tua auto usata e PERIZIATA
                alle migliori condizioni di mercato.
              </MainStrapline>
              <StoreButtons />
            </Margins>
          </LeftCol>

          <HeroImageBackgroundSplash />
        </Box>
      </Wrapper>

      <OuterBox bgColor="#f6f6f6">
        <Wrapper
          withMargin={true}
          justifyContentDesktop="flex-start"
          gapDesktop={200}
        >
          <LeftCol tabletCol={40} desktopCol={35}>
            <Titles>Cosa significa periziata?</Titles>
          </LeftCol>
          <RightCol tabletCol={60} desktopCol={40}>
            <span>
              Prima ancora di essere inserzionate, tutte le nostre auto vengono
              sottoposte a oltre
            </span>
            <FlexTwoColsContainer>
              <BigTextContainer>
                <BigTextTitle>+340</BigTextTitle>
                <BigTextSubtitle>controlli</BigTextSubtitle>
              </BigTextContainer>
              <List>
                <li>meccanici</li>
                <li>elettronici</li>
                <li>di carrozzeria</li>
              </List>
            </FlexTwoColsContainer>
            <span>
              per certificarne il chilometraggio e le condizioni, garantendo i
              più alti standard qualitativi nel settore.
            </span>
          </RightCol>
        </Wrapper>
      </OuterBox>

      <OuterBox bgColor="black">
        <ThirdWrapper withMargin={true} gapDesktop={100}>
          <LeftCol tabletCol={80} desktopCol={35}>
            <Titles>Come funziona?</Titles>
            <span>
              Acquistare con Astauto è semplicissimo: scarica l’app e cerca
              l’auto dei tuoi sogni, fai un’offerta all’asta e acquistala subito
              al prezzo che desideri.
            </span>
            {/* <button>SCOPRI DI PIU’</button> */}
          </LeftCol>
          <CustomImageDetails src={iphone2} alt="dettagli auto da app" />
        </ThirdWrapper>
      </OuterBox>

      <OuterBox>
        <Wrapper withMargin={true} gapDesktop={150} alignDesktop="center">
          <LeftCol tabletCol={50} desktopCol={38}>
            <H1MainTitle>
              Non siamo commercianti e non trattiamo aste giudiziarie
            </H1MainTitle>
          </LeftCol>
          <RightCol tabletCol={50} desktopCol={48}>
            <span>
              All’interno delle nostre aste troverai auto con un massimo di
              160.000 km e 8 anni di immatricolazione provenienti da fine
              leasing e fine noleggio, clienti fidati e partner ufficiali.
            </span>
            <br />
            <br />
            <span>
              <b>Consegniamo in tutta Italia</b> e offriamo garanzie pari al
              nuovo fino a quattro anni, finanziamenti, permute e assicurazioni
              su misura per tutte le tue esigenze.
            </span>
          </RightCol>
        </Wrapper>
      </OuterBox>

      <OuterBox bgColor="#f6f6f6">
        <CardWrapper withMargin={true}>
          <Card withBorder>
            <LeftCol tabletCol={40} desktopCol={40}>
              <Titles2>
                La differenza tra Astauto e i classici portali vetrina
              </Titles2>
            </LeftCol>
            <RightCol tabletCol={50} desktopCol={50}>
              <span>
                Con noi non c’è bisogno di trattare, chiamare il venditore o
                fidarsi. Tutte le nostre auto sono:
              </span>
              <ul>
                <li>periziate e garantite dall'Unione Nazionale Consumatori</li>
                <li>gestite interamente dal nostro servizio clienti</li>
                <li>a portata di click</li>
              </ul>
            </RightCol>
          </Card>
          <Card>
            <LeftCol tabletCol={50} desktopCol={50}>
              <Titles2>
                La differenza tra Astauto e le concessionarie della tua città
              </Titles2>
            </LeftCol>
            <RightCol tabletCol={60} desktopCol={60}>
              Astauto non vende auto usate: ti aiuta ad acquistarle. Abbiamo
              sviluppato l’UNICA piattaforma costruita attorno all’esperienza di
              acquisto per tutelare al 100% gli acquirenti.
            </RightCol>
          </Card>
        </CardWrapper>
      </OuterBox>

      <OuterBox>
        <Wrapper withMargin={true} alignDesktop={"center"}>
          <LeftCol tabletCol={50} desktopCol={40}>
            <Titles2>E per chi vuole vendere?</Titles2>
            <span>
              Scarica l’app e inserziona la tua auto. Sarai contattato da un
              nostro incaricato che perizierà GRATUITAMENTE il tuo veicolo per
              assicurarsi che rispecchi i nostri standard qualitativi.
            </span>
            <StoreButtons />
          </LeftCol>
          <RightCol tabletCol={50} desktopCol={50}>
            <StackedPhonesContainer>
              <CustomImageLeftPhone src={iphone3} />
              <CustomImageRightPhone src={iphone4} />
            </StackedPhonesContainer>
          </RightCol>
        </Wrapper>
      </OuterBox>

      <OuterBox>
        <Wrapper withMargin={true} gapDesktop={200} alignDesktop="center">
          {isDesktop ? (
            <>
              <LeftCol tabletCol={60} desktopCol={40}>
                <CustomImageOperator
                  src={isTablet || isDesktop ? operatorTablet : operator}
                  alt="operatore del settore"
                />
              </LeftCol>
              <RightCol tabletCol={60} desktopCol={50}>
                <Titles2>
                  Sono un operatore del settore. Perché dovrei lavorare con
                  Astauto?
                </Titles2>
                <span>
                  Astauto è l’unica soluzione all-in-one per vendere e
                  acquistare auto usate in maniera sistematica SENZA COSTI
                  FISSI. Con Astauto la tua attività diventerà sinonimo di
                  qualità e sicurezza, attirando nuovi clienti grazie a
                  funzionalità di marketing efficaci e automatizzate.
                </span>
              </RightCol>{" "}
            </>
          ) : (
            <>
              <LeftCol tabletCol={60} desktopCol={40}>
                <Titles2>
                  Sono un operatore del settore. Perché dovrei lavorare con
                  Astauto?
                </Titles2>
                <span>
                  Astauto è l’unica soluzione all-in-one per vendere e
                  acquistare auto usate in maniera sistematica SENZA COSTI
                  FISSI. Con Astauto la tua attività diventerà sinonimo di
                  qualità e sicurezza, attirando nuovi clienti grazie a
                  funzionalità di marketing efficaci e automatizzate.
                </span>
              </LeftCol>
              <RightCol tabletCol={60} desktopCol={40}>
                <CustomImageOperator
                  src={isTablet ? operatorTablet : operator}
                  alt="operatore del settore"
                />
              </RightCol>
            </>
          )}
        </Wrapper>
      </OuterBox>
      <OuterBox>
        <WrapperCentered withMargin={true} alignDesktop="center">
          <Titles>
            Acquistare e vendere con Astauto è <br />
            facile, veloce e sicuro.
          </Titles>
          <H3Title>
            Scarica l’App, entra in asta, e fai l’affare che hai sempre sognato!
          </H3Title>
          <StoreButtons centered />
        </WrapperCentered>
      </OuterBox>

      <OuterBox bgColor="#f6f6f6">
        <Wrapper id="wid_1684596063311" ref={widget}></Wrapper>
      </OuterBox>

      <OuterBox bgColor="black">
        <Footer>
          <FooterLeftCol tabletCol={40} desktopCol={40}>
            <AppLogo src={logoWhite} className="App-logo" alt="logo" />
          </FooterLeftCol>
          <FooterRightCol tabletCol={60} desktopCol={60}>
            <H3Title>
              Non perdere mai un’occasione.
              <br />
              <strong>Iscriviti alla nostra newsletter</strong>
            </H3Title>
            <FormContainer onSubmit={handleSubmit(onSubmit)}>
              <input
                type="email"
                placeholder="mail"
                {...register("email", { required: true })}
              />
              <Button type="submit">Invia</Button>
              {errors.email && <span>Questo campo e' obbligatorio</span>}
              {addressAdded === "true" && (
                <p>Grazie! indirizzo aggiunto alla nostra lista</p>
              )}
              {addressAdded === "loading" && <p>Registrazione in corso...</p>}
              {addressAdded === "error" && (
                <p>Si e' verificato un errore, riprovare piu' tardi</p>
              )}
            </FormContainer>
            <SocialContainer>
              <p>
                {" "}
                Seguici su{" "}
                <a
                  href="https://www.instagram.com/astautogroup/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagram} />
                </a>
              </p>
              <p>
                {" "}
                Entra nella community{" "}
                <a
                  href="https://www.facebook.com/astautogroup"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={facebook} />
                </a>{" "}
              </p>
            </SocialContainer>
            <PrivacyPolicy
              href="https://www.iubenda.com/privacy-policy/88626976"
              className="privacy-policy"
              target="_black"
            >
              Privacy Policy
            </PrivacyPolicy>
          </FooterRightCol>
        </Footer>
      </OuterBox>
    </div>
  );
}

export default App;
